import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import ModalRegistro from "../components/Modals/ModalRegistro";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Typed from 'react-typed';
import './css/index.css';
const IndexPage = () => {

  const imgGlobal = require.context('../images/assets/global', true);

  function TypedComponent(){
    return(
      <>
        <Typed className="text-title"
        strings={[
            'Aumenta tus ventas en linea',
            'Todo en un solo lugar',
            'Muestrate al mundo']}
            typeSpeed={55}
            backSpeed={20}
            loop >
        </Typed>
      </>
    )
  }
  return(
    <Layout>
      <Seo title="La mejor forma de crecer tu marca" />
      <Container fluid>
        <Row 
          className=" bg-black"
          >
          <Col xs={12} md={12} lg={6} style={{height: "550px"}}>
            <p className="text-center mt-5 mb-5 text-white" style={{paddingTop: '200px'}}>
              <TypedComponent></TypedComponent>
            </p>
          </Col>
          <Col xs={12} md={12} lg={6} style={{height: "550px"}}>
            <h2 className="text-white">Ten todo en un solo lugar</h2>
          </Col>
        </Row>
        <Row className="bg-black">
          <Col sm={12} md={12} lg={6}>
            <div style={{paddingTop: "50px", paddingLeft: "60px"}}>
              <h2 className="text-white mt-5 text-subtitle">Realiza ventas</h2>
              <p className="text-white text-description">
                Conviertete en una maquina de ventas en 10 min Todo comienza
                por un veremos y termina siempre siendo una realidad vende más hoy mismo
              </p>
              <Button variant="secondary" className="btn-try-ligth">Realiza una cotización</Button>{' '}
            </div>
          </Col>
          <Col sm={12} md={12} lg={6} className="mt-5 mb-5">
            <Row className="d-flex justify-content-center ">
              <Col sm={12} md={12} lg={6}>
                <Card className="telephone">
                  <Card.Body>
                    <Row className="d-flex justify-content-center mt-4">
                      <Col sm={12} md={12} lg={6}>
                        <div className="img-telepthone"
                        style={{
                          height: '100px',
                          width: '100px',
                            backgroundImage: 'url(https://firebasestorage.googleapis.com/v0/b/argos-tool.appspot.com/o/global%2Fundraw_male_avatar_323b.svg?alt=media&token=5c90f177-a72d-47ac-806a-e463c31ade2b)',
                            backgroundSize: '100px',
                            overflow: 'hidden'
                        }}></div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
  
};

export default IndexPage
